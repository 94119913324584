@use '@angular/material' as mat;

// mixin name will be used in main style.scss
@mixin control-component-theme($theme) {

  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  // all of these variables contain many additional variables

  .control-page {
    .container {
      .scheduler-main-container {
        .scheduling-button {
          border-color: mat.get-color-from-palette($primary, 700) !important;
          background-color: mat.get-color-from-palette($primary) !important;
          color: mat.get-color-from-palette($primary, default-contrast) !important;
        }
        .scheduler-wrapper {
          .repeat-info-label {
            .attention-span {
              color: mat.get-color-from-palette($primary) !important;
            }
          }
        }
      }

      .device-wrapper {
        .tag-device {
          .device-button {
            border-color: mat.get-color-from-palette($primary, 700) !important;
            background-color: mat.get-color-from-palette($primary) !important;
            color: mat.get-color-from-palette($primary, default-contrast) !important;
          }
        }
      }

      .product-wrapper {
        .tag-products {
          .products-button {
            border-color: mat.get-color-from-palette($primary, 700) !important;
            background-color: mat.get-color-from-palette($primary) !important;
            color: mat.get-color-from-palette($primary, default-contrast) !important;
          }
        }
      }
    }
    .save-button {
      &.disabled {
        &:hover {
          background-color: mat.get-color-from-palette($primary) !important;
        }
      }
    }
  }
}
