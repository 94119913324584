@use '@angular/material' as mat;

// mixin name will be used in main style.scss
@mixin navbar-component-theme($theme) {

  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  // all of these variables contain many additional variables
  .navbar {
    .side {
      i {
        &:hover {
          color: mat.get-color-from-palette($primary) !important;
        }
      }
    }
    /* side menu */
    .side-menu {
      .content {
        .left {
          .menu-top {
            i {
              &:hover {
                color: mat.get-color-from-palette($primary) !important;
              }
            }
          }

          nav {
            ul {
              &.selected {
                border-left-color: mat.get-color-from-palette($primary, 700) !important;
              }
            }
          }
        }
      }
    }
  }

  .navbar-login {
    .side {
      i {
        &:hover {
          color: mat.get-color-from-palette($primary) !important;
        }
      }
    }
  }

}
