@use '@angular/material' as mat;
@import "assets/sass/custom-palettes";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "assets/sass/material-custom-theme";

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;600;700&display=swap');
@import '@angular/cdk/overlay-prebuilt.css';

@include mat.core();

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
}

html, body {
    height: 100%;
}

* {
    box-sizing: border-box;
}

$app-primary: mat.define-palette($mat-orange-techmass);
$app-accent: mat.define-palette($mat-orange-techmass, A200, A100, A400);
$app-warn: mat.define-palette(mat.$pink-palette);

$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

@include mat.all-component-themes($app-theme);
@include material-custom-theme($app-theme);

// This style clashes with Vapor
/*
.cdk-overlay-connected-position-bounding-box {
  justify-content: unset !important;
  align-items: flex-start !important;
}
*/

// The following styles fix conflicts between Vapor and Material: we can remove them as soon
// as we have all the UI using Vapor
h2 {
    font-size: revert !important;
}
.datatable-header-cell-label {
    font-family: Teko, sans-serif !important;
}
.control-page {
    .save-button {
        span {
            font-family: Teko, sans-serif !important;
        }
    }
    .single-tag {
        .mat-icon {
            overflow: unset !important;
        }
    }
}

// Fix Vapor style for the empty state
.ts-empty-state__text-container {
    margin-top: 20px;
}

// Workaround for the new order drawer
.new-order-drawer {
    .ts-vapor .ts-autocomplete .ng-select {
        width: 300px !important;
    }
}

// Workaround for the new resource drawer
.resource {
    .ts-vapor .ts-autocomplete .ng-select {
        width: 300px !important;
    }
}

// Workaround for all drawers
.mic-drawer {
    .ts-vapor .ts-panel {
        border-bottom: none !important;
    }

    .ts-vapor .ts-panel__title:not(.opened) {
        background-color: transparent !important;
    }

    // Workaround for select dropdowns inside drawers
    .ts-vapor .ng-dropdown-panel.ng-select-bottom {
        max-height: 152px !important;
    }
    .ng-dropdown-panel .ng-dropdown-panel-items {
        max-height: 150px !important;
    }
}

.ts-popup__bottom-bar:has(.ts-popup__bottom-bar__left) .ts-popup__bottom-bar__right .ts-vapor.ts-button-container{
    width: auto !important;
    max-width: 400px;
}

.ts-popup__bottom-bar:has(.ts-popup__bottom-bar__left) .ts-popup__bottom-bar__right {
    max-width: 800px;
    width: auto;
}

.ts-popup__bottom-bar .ts-popup__bottom-bar__left .ts-vapor.ts-button-container:first-of-type button{
    border: solid 1px #0090d1;
    color: #0090d1 !important;
}
.ts-popup__bottom-bar .ts-popup__bottom-bar__left .ts-vapor.ts-button-container:first-of-type button:hover{
    background-color: #0090d1 !important;
    color: white !important;
}

.ts-popup__bottom-bar:has(.ts-popup__bottom-bar__left button) .ts-popup__bottom-bar__right .ts-vapor.ts-button-container:first-of-type button{
    border: solid 1px #d82829;
    color: #d82829 !important;
}
.ts-popup__bottom-bar:has(.ts-popup__bottom-bar__left button) .ts-popup__bottom-bar__right .ts-vapor.ts-button-container:first-of-type button:hover{
    background-color: #fc4e3d !important;
    color: white !important;
}

.cdk-overlay-pane.ts-overlay-center{
    position: sticky;
    top: 300px;
    margin-top: 300px;
    z-index: 2000;
}

.cdk-overlay-container.ts-vapor.ts-popup__container > div:last-of-type > div{
    max-width: 1000px;
}

.cdk-overlay-container.ts-vapor.ts-popup__container > div:last-of-type > div > div{
    max-width: 1000px;
}

#cdk-overlay-200 > div > ts-bottom-bar{
    max-width: 1000px;
}

.ts-popup__bottom-bar__right{
    max-width: 700px !important;
}

#cdk-overlay-200 > div > ts-bottom-bar > div.ts-popup__bottom-bar__right{
    max-width: 700px !important;
}

.treelist-element.ts-scrollable .dx-treelist-headers {
    z-index: 5 !important;
}

.dx-datebox {
    z-index: 5 !important;
}

// Remove validation text for the filters of the orders list page:
// there is no validation there and it only takes space
.orders-list-page {
    .ts-vapor .ts-validation-text {
        display: none !important;
    }
}

.resources-page {
    .dx-treelist-icon-container >.dx-treelist-empty-space:nth-of-type(1):not(:last-of-type){
    width: 40px !important;
    min-width: 40px !important;
    }
    .dx-treelist-icon-container >.dx-treelist-empty-space:nth-of-type(2):not(:last-of-type){
    width: 54px !important;
    min-width: 54px !important;
    }
    .dx-treelist-icon-container >.dx-treelist-empty-space:nth-of-type(3){
    width: 0px !important;
    min-width: 0px !important;
    }
}

.res-form {
    .table-header-caption{
        margin-top: 5px;
    }
}

.gen-dialog-wrp {
    span {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;        
    }
    button {
        padding: 12px 16px !important;
        border-radius: 8px !important;
    }
    .save:not(:active) *{
        background-color: #0077AD !important;
        color: white;
    }
    .save:hover *{
        background-color: rgb(0, 144, 209) !important;
    }
    .cancel *{
        text-decoration: underline !important;
    }
    .cancel:hover button{
        border: 1px solid rgb(0, 144, 209) !important;;
    }
}