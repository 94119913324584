@use '@angular/material' as mat;

// mixin name will be used in main style.scss
@mixin task-dialog-component-theme($theme) {

  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  // all of these variables contain many additional variables
  .task-dialog {
    .copy-element {
      .is-selected {
        background-color: mat.get-color-from-palette($primary) !important;
      }
    }
    .task-container {
      .input-instruction-container {
        .wrapper {
          [type="file"] + label {
            background: mat.get-color-from-palette($primary) !important;
            &:hover {
              background-color: mat.get-color-from-palette($primary, 700) !important;
            }
          }
        }
      }
    }
  }
}
