@use '@angular/material' as mat;

// mixin name will be used in main style.scss
@mixin work-shift-component-theme($theme) {

  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  // all of these variables contain many additional variables
  .shift-page {
    .mat-ink-bar {
      background-color: mat.get-color-from-palette($primary, 700) !important;
    }

    .cal-week-view {
      .cal-day-headers {
        .cal-header:hover,
        .cal-drag-over {
          background-color: mat.get-color-from-palette($background, card);
        }
      }

      .cal-header.cal-weekend span {
        color: mat.get-color-from-palette($primary, A100);
      }

      .cal-header.cal-today {
        background-color:mat.get-color-from-palette($primary);

        span {
          color:mat.get-color-from-palette($primary, default-contrast);
          opacity: 1;
        }
      }

      .cal-header.cal-today:hover {
        background-color: mat.get-color-from-palette($primary, 600);
      }

      .cal-event {
        background-color: mat.get-color-from-palette($primary) !important;
        border-color: mat.get-color-from-palette($primary, lighter) !important;
        color: mat.get-color-from-palette($primary, default-contrast) !important;
      }
    }
  }
}
