@use '@angular/material' as mat;

// mixin name will be used in main style.scss
@mixin new-element-button-component-theme($theme) {

  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  // all of these variables contain many additional variables

  .add-new-element-button {
    border-color: mat.get-color-from-palette($primary, 700) !important;
    background-color: mat.get-color-from-palette($primary) !important;
    color: mat.get-color-from-palette($primary, default-contrast) !important;
    &:hover {
      background-color: mat.get-color-from-palette($primary, 700) !important;
    }
    &.disabled {
      &:hover {
        background-color: mat.get-color-from-palette($primary) !important;
      }
    }
  }
}
